import { getPageLanguage, isNull } from '@/logic/helpers/utils';
import { JOURNEY_MODE } from '../../models/trips/journeyModes';
import { stationName } from '@/filters';

export const addDiscountPerTrip = (ecommerceData, couponInfo) => {
  if (couponInfo.length === 0) {
    ecommerceData.items.forEach((item) => {
      item.discount = 0;
    });
  } else {
    ecommerceData.items.forEach((item, index) => {
      item.discount = couponInfo[index].Discount;
      item.price = item.price - couponInfo[index].Discount;
    });
  }
  return ecommerceData;
};

export const bookingToEcommerceCheckout = (totalAmount, trips, allSearchPorts) => {
  let destinationPortIndex = 1;
  return {
    currency: 'EUR',
    value: totalAmount,
    items: trips.map((trip) => {
      const origin = allSearchPorts[destinationPortIndex - 1];
      const destination = allSearchPorts[destinationPortIndex];
      let payload = {
        ...directTripToEcommerceItem(trip, trip.subJourney.type),
        item_list_id: portsAbbrToItemListId({ origin, destination }, trip.subJourney.type),
        item_list_name: portsAbbrToListName({ origin, destination }),
      };

      if (trip.Details.ArrStation === destination) {
        destinationPortIndex++;
      }

      return payload;
    }),
  };
};

export const resultsToEcommerce = (trips, searchPorts, enforcedMode) => {
  if (trips.length === 0) {
    return {
      item_list_id: portsAbbrToItemListId(searchPorts, enforcedMode),
      item_list_name: portsAbbrToListName(searchPorts),
      currency: 'EUR',
      items: [],
    };
  }
  const isListWithIndirects = trips.some((trip) => trip.subJourney.type === JOURNEY_MODE.INDIRECT);
  const mode = isListWithIndirects ? JOURNEY_MODE.INDIRECT : trips[0].subJourney.type;
  let tripIndex = 1;

  return {
    item_list_id: portsAbbrToItemListId(searchPorts, mode),
    item_list_name: portsAbbrToListName(searchPorts),
    currency: 'EUR',
    items: trips.map((trip) => {
      let payload = {
        ...directTripToEcommerceItem(trip, mode, tripIndex),
        item_list_id: portsAbbrToItemListId(searchPorts, mode),
        item_list_name: portsAbbrToListName(searchPorts),
      };

      if (trip.Details.ArrStation === searchPorts.destination) tripIndex++;

      return payload;
    }),
  };
};

export const tripToEcommerce = (trip, searchPorts, enforcedPosition) => {
  try {
    if (trip.isDirectTrip) {
      return directTripToEcommerce(trip, searchPorts, enforcedPosition);
    }
    if (!trip.isDirectTrip) {
      return indirectTripToEcommerce(trip, searchPorts);
    }
  } catch (exception) {
    return {};
  }
};

/**
 * Converts a direct trip to a GA4 ecommerce object
 *
 * @param {DirectTrip} trip
 * @param {int|null} enforcedPosition
 * @returns
 */
const directTripToEcommerce = (trip, searchPorts, enforcedPosition) => {
  return {
    items: [
      {
        ...directTripToEcommerceItem(trip, JOURNEY_MODE.DIRECT, enforcedPosition),
        item_list_id: portsAbbrToItemListId(searchPorts, JOURNEY_MODE.DIRECT),
        item_list_name: portsAbbrToListName(searchPorts),
      },
    ],
    currency: 'EUR',
  };
};

/**
 * Converts a direct trip to a GA4 ecommerce object
 *
 * @param {DirectTrip} trip
 * @param {{origin: string, destination: string}} searchPorts
 * @returns
 */
const indirectTripToEcommerce = ({ segments, indirectTripType, productPosition }, searchPorts) => {
  return {
    items: segments.map((s) => ({
      ...directTripToEcommerceItem(s, indirectTripType, productPosition),
      item_list_id: portsAbbrToItemListId(searchPorts, indirectTripType),
      item_list_name: portsAbbrToListName(searchPorts),
    })),
    currency: 'EUR',
  };
};

/**
 * Converts a direct trip (or a segment of an indirect trip) into a GA4 ecommerce item
 * @param {DirectTrip} trip
 * @param {int|null} enforcedPosition
 * @returns
 */
const directTripToEcommerceItem = (trip, journeyMode, enforcedPosition) => {
  const { timings, minPrice, OverallPrice, Details } = trip;
  return {
    item_id: `${Details.Company}_${Details.DepStation}_${Details.ArrStation}_${timings.DepDate}_${timings.DepTime}`,
    item_name: `${stationName(Details.DepStation)} - ${stationName(Details.ArrStation)}`,
    item_brand: Details.Company,
    item_category: journeyModeToCategory(journeyMode),
    item_category2: `${Details.DepCountry} - ${Details.ArrCountry}`,
    item_category3: trip.metadata.marketingSubregionName,
    item_variant: `${Details.VesselID}`,
    quantity: 1,
    index: getProductPosition(trip, enforcedPosition),
    price: minPrice ? minPrice : OverallPrice,
  };
};

const getProductPosition = (trip, enforcedPosition) => {
  let productPosition = trip.productPosition;
  if (isNull(productPosition)) {
    productPosition = isNull(enforcedPosition) ? 999 : enforcedPosition;
  }
  return productPosition;
};

const journeyModeToCategory = (journeyMode) => {
  switch (journeyMode) {
    case JOURNEY_MODE.INDIRECT:
      return 'Indirect';
    case JOURNEY_MODE.MULTISEGMENT:
      return 'Conjunction';
    default:
      return 'Direct';
  }
};

/**
 * Returns item_list_id of analytics payload
 * @param {{origin:String, destination:String}} searchPorts
 * @param {String} mode
 * @returns
 */
const portsAbbrToItemListId = (searchPorts, mode) => {
  const origin = stationName(searchPorts.origin);
  const destination = stationName(searchPorts.destination);
  const ports = origin < destination ? `${searchPorts.origin}_${searchPorts.destination}` : `${searchPorts.destination}_${searchPorts.origin}`;
  return `${getPageLanguage()}_${journeyModeToCategory(mode)}_${ports}`;
};

const portsAbbrToListName = (searchPorts) => {
  const origin = stationName(searchPorts.origin);
  const destination = stationName(searchPorts.destination);
  return origin < destination ? `${origin} - ${destination}` : `${destination} - ${origin}`;
};
