<template>
  <div :class="['notification', extraClass, mainClass]" data-test="notification">
    <i :class="iconClass" v-if="hasIcon" />
    <div v-if="message !== ''" class="notification__message" data-test="notificationMessage">{{ message }}</div>
    <slot v-else name="notificationMessage" class="notification__message"></slot>
  
  </div>
</template>

<script>
import { isNullOrEmptyString } from '@/logic/helpers/utils';
export default {
  name: 'Notification',
  props: {
    message: {
      type: String,
      default: ''
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    hasRadius: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: true
    },
    success: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: true
    },
    customIconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    extraClass() {
      const borderClass = this.hasBorder ? 'notification--with-border' : '';
      const radiusClass = this.hasRadius ? 'notification--with-radius' : '';
      return `${borderClass} ${radiusClass}`;
    },
    mainClass() {
      if (this.warning) return 'notification--warning';
      if (this.success) return 'notification--success';
      return 'notification--info';
    },
    iconClass() {
      const mainClass = 'fh notification__icon';
      let iconClass = this.warning ? 'fh-notification' : 'fh-info';
      if (!isNullOrEmptyString(this.customIconClass)) iconClass = this.customIconClass;
      return `${mainClass} ${iconClass}`;
    }
  }
};
</script>
