import settings from '@/settings';
import PortsRepository from '@/logic/repositories/PortsRepository';
import { message } from '@/logic/helpers/utils';
import { createTravelerDescription } from '@/logic/generators/phrases';
import { minutesToDurationStr } from '@/logic/helpers/stringUtils';

// translates a message using the utils service
export const trans = (value) => message(value);

// converts a price string to a number with decimal digits
export const currency = (value) => Number(value).toFixed(2);

export const gender = (gender) => settings.translations.genders[gender];

// converts a price string to a number with decimal digits
export const currencyFromRaw = (value) => (Number(value) / 100).toFixed(2);

// converts a price string to two decimal digits with a euro sign after the value
export const currencyString = (value) => `${Number(value).toFixed(2)} €`;

export const remainingSeatsString = (quantity) => (quantity > 1 ? message('ticketSelection.seatsLeft.other') : message('ticketSelection.seatsLeft.one'));

// converts a price string to two decimal digits with a euro sign before the value
export const currencyStringPre = (value) => {
  if (typeof value === 'undefined') return '';
  return ` € ${Number(value).toFixed(2)}`;
};

// Converts a currency into the way presented in aegean website, using
// an html element to make numbers after decimal point smaller in size
export const aegeanCurrency = (value) => {
  if (!value) return '';
  let valueString = `${Number(value).toFixed(2)}`;
  let valueStringParts = valueString.split('.');
  return `&euro; ${valueStringParts[0]}.<small>${valueStringParts[1]}</small>`;
};

// Converts a number of minutes into duration string
// Eg. 125 -> 2h 5m
export const durationString = (minutes) => minutesToDurationStr(minutes, ' ');

// converts a port abbreviation into a location alias
export const portAlias = (value) => PortsRepository.getPortName(value);

// converts a port abbreviation into port's full name
export const portFullName = (value) => PortsRepository.getPortFullName(value);

// converts a port abbreviation into port's english name
export const stationName = (value) => PortsRepository.getStationName(value)

// convert a dayjs object into a date with a localised format
export const shortDate = (date) => date.format(settings.formats.localizedDateFormat);

// Separate an array of words by |
export const separatedWords = (wordsArray) => wordsArray.join(' | ');

// Create friendly description for passenger */
export const passDescription = (passenger) => {
  let passengerGenderVariable = passenger.gender === settings.constants.GENDERS.MALE ? 'masculine' : 'feminine';
  return createTravelerDescription(passenger.passengerIndex, `generic.passenger.capitalised.${passengerGenderVariable}.one`);
};

// Create friendly description for passenger
export const petDescription = (pet) => createTravelerDescription(pet.petIndex, 'generic.pet.capitalised.one');

// Create friendly description for vehicle
export const vehDescription = (vehicle) => createTravelerDescription(vehicle.vehicleIndex, 'generic.vehicle.capitalised.one');

// Adds minus and percentage signs to a value
export const percentageString = (value) => `${-value}%`;

export const fhImage = (suffix) => `${window.ferryhopper.cdn || 'https://images.ferryhopper.com/'}${suffix}`;

export const setDefaultImage = (e) => (e.target.src = fhImage('assets/logo/ferryhopper-marker-32.png'));
