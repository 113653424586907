import { orderBy as _orderBy } from 'lodash-es';
import { joinNamesWithSeparator, message } from '@/logic/helpers/utils';
import { arrayUnique } from '@/logic/helpers/arrayFunctions';
import { titleCase } from '@/logic/helpers/stringUtils';

/**
 * Creates a string of that describes a group of passengers
 * Eg. Passengers 1, 2 & 3
 * @param {import('@/logic/models/Models').PassengerModel[]} passengers
 */
export const createPassengerGroupString = passengers => {
  let sortedOccupantNames = _orderBy(passengers, 'passengerIndex').map(o => `${o.passengerIndex + 1}`);
  return joinNamesWithSeparator(sortedOccupantNames, message('generic.passenger.capitalised.masculine.one'), message('generic.passenger.capitalised.masculine.other'));
};

/**
 * Creates a string of that describes a group of pets
 * Eg. Pets 1, 2 & 3
 * @param {import('@/logic/models/PetModel').PetModel[]} pets
 */
export const createPetsGroupString = pets => {
  let sortedOccupantNames = _orderBy(pets, 'petIndex').map(p => `${p.petIndex + 1}`);
  return joinNamesWithSeparator(sortedOccupantNames, message('generic.pet.lowercase.one'), message('generic.pet.lowercase.other'));
};

/**
 * Return a concatenated string of operating companies, removing duplicates
 * @param {String[]} companies
 */
export const createSelectedCompaniesString = companies => {
  return titleCase(joinNamesWithSeparator(arrayUnique(companies), '', ''));
};

/**
 * Returns the description of a cabin for seating analysis
 * Eg. a full 4-berth cabin is Full cabin (4 beds)
 * @param {Accommodation} cabin
 */
export const createSeatingAnalysisCabinDescription = cabin => {
  const cabinSubdescription = cabin.accommodationCapacity > 1 ? message('beds') : message('bed');
  return `${message('filledcabin')} (${cabin.accommodationCapacity} ${cabinSubdescription})`;
};

/**
 * Creates the description of single berth selections.
 * Eg. a single berth in 4-berth cabin would is bed in two bed cabin
 *
 * If we want to switch to pular (eg. beds) we need to also use the 'manybeds' message and
 * know how many passengers have selected it
 *
 * @param {Accommodation} accommodation
 */
export const createSingleBerthAnalysisDescription = accommodation => `${message('onebed')} ${accommodation.Category.toLowerCase()}`;

/**
 * Creates a description for a traveler(passenger, vehicle, pet), based on his position in the forms
 * eg. Passenger 1, passenger 2 etc.
 * @param {number} travelerIndex
 */
export const createTravelerDescription = (travelerIndex, travelerType) => `${message(travelerType)} ${travelerIndex + 1}`;

/**
 * Creates the description of selected passengers in all search-related components (eg. search mask inputs)
 * @param {Number} passengersCount
 * @returns {String}
 */
export const createPassengersString = passengersCount => (passengersCount > 1 ? `${passengersCount} ${message('passengers')}` : `${passengersCount} ${message('passenger')}`);

/**
 * Creates the description of selected vehicles in all search-related components (eg. search mask inputs)
 * @param {Number} vehiclesCount
 * @returns {String}
 */
export const createVehiclesString = vehiclesCount => {
  if (vehiclesCount === 0) return message('novehicleslabel');

  return vehiclesCount > 1 ? `${vehiclesCount} ${message('vehicleslabel')}` : `${vehiclesCount} ${message('vehiclelabel')}`;
};
