import { isJsonPropTrue, isNull } from '@/logic/helpers/utils';

// id for the default region (fallback)
export const DEFAULT_PROVIDER = 'FHForthCRS';
export const DEFAULT_REGION_ID = '0';

export const adaptPort = portDto => {
  const { LocationAbbr, parentIsland, alias, short, lat, lon, region, country, countryName, popularity, alternates, providers, destinations, connectionPorts, island, children, name: stationName } = portDto;

  const portProviders = isNull(providers) || providers === '' || providers === 'NOPROVIDER' ? null : (!!providers && providers.split(',').map(p => p.trim())) || [DEFAULT_PROVIDER];
  const portAlternates = `${alias}, ${(alternates || '').trim()}`.toLowerCase();
  const portChildren = (!!children && children.split(',')) || [];
  const portDestinations = isNull(destinations) || destinations === '' ? null : destinations;

  const adapted = {
    LocationAbbr,
    parentIsland,
    lat,
    lon,
    country,
    countryName,
    destinations: portDestinations,
    alias,
    isIsland: isJsonPropTrue(island),
    popularity: Number(popularity) || 0,
    alternates: portAlternates,
    short: short || alias,
    children: portChildren,
    providers: portProviders,
    region: region || DEFAULT_REGION_ID,
    stationName
  };

  const isValidIsland = !adapted.isIsland || adapted.children.length > 0;
  const hasValidProps = !!adapted.LocationAbbr && !!adapted.alias && !!adapted.country;

  return {
    ...adapted,
    connections: adaptConnectedPorts(connectionPorts),
    isValid: hasValidProps && isValidIsland
  };
};

const adaptConnectedPorts = connectionPorts => {
  let connections = [];
  if (connectionPorts) {
    connections = connectionPorts
      .split(',')
      .map(p => p.trim())
      .map(connectedPortCode => ({
        abbr: connectedPortCode
      }));
  }
  return connections;
};
