import { message, getConfigurationNumber, getConfigurationValue, getConfigurationArray } from '@/logic/helpers/utils';

/**
 * Initialize window ferryhopper if not exists. Techically this should never happen, as this
 * object is created on the initial page load by the server
 */
window.ferryhopper = window.ferryhopper || {};

/**
 * Companies that require to maintain the FareCode returned during pricing, in order for the
 * booking to go throught (normally, these should be generated from the server, but we have this fallback)
 */
let keepFareCodeCompanies = getConfigurationArray('keep_fare_code_companies', ['AQB', 'MEDP']);

export default {
  /**
   * Default values
   */
  defaults: {
    defaultVehicleCategory: '1',
    GENDER: 'M',
    // the minimum category level for caravans
    // used in order to hide caravans in domestic trips
    // todo: this is not the best mechanism
    defaultCaravanMinCategory: 6
  },

  /**
   * constant definitions used as strings throughout the app
   * some of these strings are also used in our APIs so make sure not to change them
   */
  constants: {
    NO_SELECTION: 'NO_SELECTION',
    DECK: 'DECK',
    SEAT: 'SEAT',
    CABIN: 'CABIN',
    BED: 'BED',
    PASSENGER: 'PASSENGER',
    VEHICLE: 'VEHICLE',
    PET: 'PET',
    GENDERS: {
      MALE: 'M',
      FEMALE: 'F'
    }
  },

  /**
   * Various numeric limits that we use throughout the app, some of them also set by
   * the server during initial page load, but we also have a fallback in case one of the
   * critical variables is (for any reason) not returned by the server
   */
  limits: {
    SEARCH_MAX_TRIPS: 4,
    SEARCH_MIN_TRIPS: 1,
    MINIMUM_PASSENGERS: 1,
    MAXIMUM_PASSENGERS: 9,
    MINIMUM_VEHICLES: 0,
    MAXIMUM_VEHICLES: 4,
    MINIMUM_PETS: 0,
    MAXIMUM_PETS: 9,
    MAXIMUM_TRIPS_PER_BOOKING: 5,
    MINIMUM_PORT_WAIT_TIME: getConfigurationNumber('min_wait_time', 45),
    MINIMUM_CONJUNCTION_WAIT_TIME: getConfigurationNumber('min_conjunction_wait_time', 5),
    MAXIMUM_PORT_WAIT_TIME: getConfigurationNumber('max_wait_time', 4000),
    CLOSE_TRIP_WARNING_LIMIT: getConfigurationNumber('close_trip_warning', 15),
  },

  /**
   * Features that can be enabled/disabled on demand
   * Mostly generated by the server
   */
  features: {
    DISABLE_INDIRECT_TRIPS: getConfigurationValue('disable_indirect_trips', false)
  },


  /**
   * CRS-specific parameters
   */
  crs: {
    // list of companies that we need to send back the response fare code on
    // ticket issuing
    keepFareCodeCompanies: keepFareCodeCompanies
  },

  /**
   * API communications parameters
   */
  api: {
    // these settings are related to Version 2 of our API (with polling)
    POLLING_INITIAL_DELAY: getConfigurationNumber('polling_initial_delay', 500),
    POLLING_INTERVAL: getConfigurationNumber('polling_interval', 1500),
    POLLING_MAX_RETRIES: getConfigurationNumber('polling_max_retries', 20)
  },

  /**
   * Datetime formats used throughout the app
   * REQUIRES TRANSLATION - add a representation for each supported locale
   * Change cautiously as some of these formats are used in the APIs and
   * for our communications with providers
   */
  formats: {
    // the date format that microapi returns for itineraries
    microApiDateTimeFormat: 'YYYY-MM-DD HH:mm',
    // FCRS request format
    dayjsToFCRS: 'YYYYMMDD',
    // format for dates used inside the booking flow
    localizedDateFormat: 'll',
    localizedDateFormatLong: 'lll',
    localizedDateFormatFull: 'llll',
    birthdayFormat: 'LL',
    localizedDateFormatFullWithShortMonth: 'LLL',
  },

  /**
   * Generic constant variables used throughout the app
   */
  variables: {
    // used in gender-selector of PassengersTab to provide the available
    // passenger genger options
    genders: [
      {
        id: 'option-male',
        value: 'M',
        text: message('mr')
      },
      {
        id: 'option-female',
        value: 'F',
        text: message('ms')
      }
    ]
  },

  /**
   * Icons for the supported seat and vehicle types
   */
  icons: {
    seats: {
      NO_SELECTION: '<i class="fh fh-notification"></i>',
      CABIN: '<i class="fh fh-user-fill"></i>',
      SEAT: '<i class="fh fh-user-fill"></i>',
      CAR: '<i class="fh fh-car"></i>',
      DECK: '<i class="fh fh-user-fill"></i>',
      INFANT: '<i class="fh fh-infant2"></i>'
    },
    vehicles: {
      CAR: '<i class="fh fh-car"></i>',
      MOTORBIKE: '<i class="fh fh-motorcycle"></i>',
      MOTORHOME: '<i class="fh fh-caravan"></i>',
      ONBOARD: '<i class="fh fh-on-board"></i>',
      BICYCLE: '<i class="fh fh-bicycle"></i>'
    }
  },

  /**
   * Translation messages used in parts of the app
   */
  translations: {
    // converts gender codes to translated strings - used in BookingTab
    // on the label that appears on top of the passenger names inputs
    genders: {
      M: message('mr'),
      F: message('ms')
    }
  }
};
