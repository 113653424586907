/* eslint-disable no-undef */

import { tripToEcommerce, bookingToEcommerceCheckout, resultsToEcommerce, addDiscountPerTrip } from './tripToProductData';
import { getConfigurationValue, getPageLanguage, getPageName, isEmpty } from '@/logic/helpers/utils';

const GA4_EVENTS_VERSION_ID = getConfigurationValue('ga4_events_version_id', 'Unknown');

const hasGoogleAnalytics4 = () => typeof gtag !== 'undefined';
const hasFacebookPixel = () => typeof fbq !== 'undefined';

const ga4DefaultPayload = () => ({
  locale: getPageLanguage(),
  screen: getPageName(),
  version_id: GA4_EVENTS_VERSION_ID,
});

export const userTimingEvent = (timingCategory, timingVar, timingValue) => {
  //LostEvent:timing
};

export const ga4Event = (eventName, payload) => {
  if (!hasGoogleAnalytics4()) return;

  gtag('event', eventName, {
    ...ga4DefaultPayload(),
    ...payload,
  });
};

export const fbPayment = (value) => {
  if (hasFacebookPixel()) {
    fbq('track', 'InitiateCheckout', {
      currency: 'EUR',
      value: value,
    });
  }
};

export const ga4AddProduct = (trip, searchPorts) => {
  const ecommerceData = tripToEcommerce(trip, searchPorts);

  if (hasGoogleAnalytics4()) {
    gtag('event', 'add_to_cart', {
      ...ga4DefaultPayload(),
      value: trip.minPrice || trip.OverallPrice,
      ...ecommerceData,
    });
  }

  if (hasFacebookPixel()) {
    fbq('track', 'AddToCart', {
      content_ids: ecommerceData.items.map((i) => i.item_id),
      value: trip.minPrice,
      currency: 'EUR',
    });
  }
};

export const ga4RemoveProduct = (trip, searchPorts) => {
  if (hasGoogleAnalytics4()) {
    gtag('event', 'remove_from_cart', {
      ...ga4DefaultPayload(),
      value: trip.minPrice || trip.OverallPrice,
      ...tripToEcommerce(trip, searchPorts),
    });
  }
};

export const ga4ShippingInfo = (totalAmount, trips, couponInfo, allSearchPorts) => {
  if (hasGoogleAnalytics4()) {
    gtag('event', 'add_shipping_info', {
      ...ga4DefaultPayload(),
      ...addDiscountPerTrip(bookingToEcommerceCheckout(totalAmount, trips, allSearchPorts), couponInfo),
      coupon: isEmpty(couponInfo) ? '' : couponInfo[0].CouponCode,
    });
  }
};

export const ga4BeginCheckout = (totalAmount, trips, allSearchPorts) => {
  if (!hasGoogleAnalytics4()) return;
  ga4Event('begin_checkout', {
    ...ga4DefaultPayload(),
    ...bookingToEcommerceCheckout(totalAmount, trips, allSearchPorts),
  });
};

export const ga4ViewItem = (trip, searchPorts) => {
  if (!hasGoogleAnalytics4()) return;
  ga4Event('view_item', {
    ...ga4DefaultPayload(),
    value: trip.minPrice || trip.OverallPrice,
    ...tripToEcommerce(trip, searchPorts),
  });
};

export const ga4ViewItemList = (trips, searchPorts, enforcedMode) => {
  if (!hasGoogleAnalytics4()) return;
  ga4Event('view_item_list', {
    ...ga4DefaultPayload(),
    ...resultsToEcommerce(trips, searchPorts, enforcedMode),
  });
};

export const ga4ViewPromotion = (trip, searchPorts, labels) => {
  if (!hasGoogleAnalytics4()) return;
  ga4Event('view_promotion', {
    ...ga4DefaultPayload(),
    ...tripToEcommerce(trip, searchPorts),
    creative_name: labels.join('_'),
  });
};

export const ga4SelectPromotion = (trip, searchPorts, labels) => {
  if (!hasGoogleAnalytics4()) return;
  ga4Event('select_promotion', {
    ...ga4DefaultPayload(),
    ...tripToEcommerce(trip, searchPorts),
    creative_name: labels.join('_'),
  });
};
