/**
 * Returns true if a value is the first occurence in an array
 */
function isFirstOccurence(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 * Returns the unique values from a normal array
 */
export const arrayUnique = arrayIn => {
  return arrayIn.filter(isFirstOccurence);
};

/**
 * Returns true if an array contains duplicate values
 */
export const hasDuplicates = array => {
  return new Set(array).size !== array.length;
};

/**
 * Splits an array in 2 chunks based on an index and returns
 * an array with these chunks reversed
 */
export const restartArrayFromIndex = (array, index) => {
  let itemsBefore = array.slice(0, index);
  let itemsAfter = array.slice(index);
  return [...itemsAfter, ...itemsBefore];
};
