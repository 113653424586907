import { keys as _keys, clone as _clone, intersectionWith as _intersectionWith } from 'lodash-es';
import settings from '@/settings';
import { isNull, notNull } from '@/logic/helpers/utils';
import { arrayUnique } from '@/logic/helpers/arrayFunctions';
import { adaptPort, DEFAULT_REGION_ID } from './../adapters/portsAdapter';

// full list of ports
let locationsMap = [];
let locations = [];
let popularLocations = [];

// create regions array and add a default region that will be assigned to ports that do no
// have a given region - we implemented region on FH30
let regions = [
  {
    id: DEFAULT_REGION_ID,
    connections: [DEFAULT_REGION_ID]
  }
];

export default {
  /**
   * Populates the detailed list of destinations according to api-fetched data
   */
  mapLocations(data) {
    const validPorts = data.map(adaptPort).filter(p => p.isValid);
    const simplePorts = validPorts.filter(p => !p.isIsland);

    const childrenWithParents = [];
    const superPorts = validPorts
      .filter(p => p.isIsland)
      .map(superport => {
        const validChildren = superport.children
          .map(childCode => {
            return simplePorts.find(p => p.LocationAbbr === childCode);
          })
          .filter(child => !!child);

        const superportChildren = validChildren.map(child => child.LocationAbbr);
        const superportDestinationsString = validChildren
          .map(child => child.destinations)
          .join(',');

        const superportRegions = arrayUnique(
          validChildren.map(child => {
            return child.region;
          })
        );

        superportChildren.forEach(childCode => {
          childrenWithParents[childCode] = superport.LocationAbbr;
        });

        return {
          ...superport,
          children: superportChildren,
          destinations: superportDestinationsString,
          regions: superportRegions
        };
      });

    // after all data have been initialized and set, loop through again and update parentIsland codes,
    // and merge the two arrays
    locations = [...simplePorts, ...superPorts].map(port => {
      return {
        ...port,
        parentIsland: childrenWithParents[port.LocationAbbr],
        destinationPortCodes: (port.destinations || '').split(',')
      };
    });

    locations.forEach(location => {
      locationsMap[location.LocationAbbr] = location;
    });
  },

  /**
   * Create an array of regions and their connections, in order to identify
   * searches that cannot be perfomed due to regions being far from each other
   */
  mapRegions(regionConnections) {
    regionConnections.forEach(regionConnection => {
      // if regionA is not in the array of regions, create it
      if (typeof regions[regionConnection.regionA] === 'undefined') {
        regions[regionConnection.regionA] = {
          id: regionConnection.regionA,
          connections: [regionConnection.regionB]
        };
        // all regions are connected to the default region.
        // this provides a fallback for islands that have not a set region
        regions[DEFAULT_REGION_ID].connections.push(regionConnection.regionA);
      } else {
        // add connection to existing region
        regions[regionConnection.regionA].connections.push(regionConnection.regionB);
      }
    });
  },

  mapPortSuggestions(portSuggestions) {
    popularLocations = portSuggestions.map(suggestion => this.getPort(suggestion.code)).filter(notNull);
  },

  /**
   * Initialize ports repository
   * @param {{locations: Object[], regions: Object[], [portSuggestions]: Object[]|undefined}} data
   */
  syncInitialize(data) {
    this.mapRegions(data.regions);
    this.mapLocations(data.locations);
    this.mapPortSuggestions(data.portSuggestions || []);
  },

  /*
   * Initialize ports repository
   * @param {{locations: Object[], regions: Object[], portSuggestions: Object[]|undefined}} data
   */
  initialize(data) {
    return new Promise((resolve, reject) => {
      this.syncInitialize(data);
      resolve(true);
    });
  },

  /**
   * Internal function used to get two ports either by string codes
   * @param {object|string} firstPort
   * @param {object|string} secondPort
   */
  _getPortPairs(firstPort, secondPort) {
    // convert inputs to port objects
    let isFirstPortString = typeof firstPort === 'string' || firstPort instanceof String;
    let isSecondPortString = typeof secondPort === 'string' || secondPort instanceof String;
    let a = isFirstPortString ? locationsMap[firstPort] : firstPort;
    let b = isSecondPortString ? locationsMap[secondPort] : secondPort;
    return {
      a,
      b
    };
  },
  //--------------------------------------------------------------------------
  // returns true if the selected destination belongs to the available destinations
  // of an origin. It works with either object inputs or string inputs (abbreviations)
  // This functionality is used in order to perform a query for results, and hence it
  // should not take into account ports that are connected via parent islands
  isDirectlyConnected(origin, destination) {
    const { a, b } = this._getPortPairs(origin, destination);

    // return false if the given ports cannot be found
    if (!a || !b) {
      return false;
    }

    let isDirectConnectionPossible = a.destinationPortCodes.includes(b.LocationAbbr) || b.destinationPortCodes.includes(a.LocationAbbr);

    return isDirectConnectionPossible;
  },

  /**
   * This function will return true if two ports (provided either by code or as objects)
   * belong to a connected region, or belong to the same region (Which can also be a null region for
   * ports that we have not defined yet)
   */
  isConnectionPossible(origin, destination) {
    const { a, b } = this._getPortPairs(origin, destination);

    if (settings.features.DISABLE_INDIRECT_TRIPS) {
      return this.isDirectlyConnected(origin, destination);
    }

    // return false if the given ports cannot be found
    if (!a || !b) {
      return false;
    }

    let regionsA = a.isIsland ? a.regions : [a.region];
    let regionsB = b.isIsland ? b.regions : [b.region];

    const connectedRegion = regionsA.find(rA => regionsB.find(rB => this.areRegionsConnected(rA, rB)));
    return typeof connectedRegion !== 'undefined';
  },

  /**
   * Returns true if two regions are connected
   * @param {String} regionA
   * @param {String} regionB
   */
  areRegionsConnected(regionA, regionB) {
    if (regionA === regionB) return true;
    if (regions[regionA] && regions[regionA].connections && regions[regionA].connections.includes(regionB)) return true;
    if (regions[regionB] && regions[regionB].connections && regions[regionB].connections.includes(regionA)) return true;
    return false;
  },

  //--------------------------------------------------------------------------
  // Given origin and destination, it provides an array of connections taking
  // into account the nature of each location (island or single port)
  // Ignore connections flag is used for double route where origin and destination
  // might not be directly connected
  getSearchAnalysis(origin, destination, ignoreConnections) {
    let originPort = this.getPort(origin);
    let destinationPort = this.getPort(destination);
    if (!originPort || !destinationPort) {
      return [];
    }

    // If origin is an island, add it's children ports in list
    let origins = originPort.isIsland ? originPort.children : [origin];

    // If destination is an island, add it's children ports in list
    let destinations = destinationPort.isIsland ? destinationPort.children : [destination];

    // Initialize empty search array and fill it with the subsearches that
    // should be performed for the given inputs
    let searches = [];
    // For each in origins
    origins.forEach(or => {
      // For each in destinations
      destinations.forEach(de => {
        // If ports are connected or ignoreConnections is set to true, add to search array
        if (this.isDirectlyConnected(or, de) || ignoreConnections) {
          searches.push({ or: or, de: de });
        }
      });
    });

    return searches;
  },

  isSameIsland(origin, destination) {
    const { a, b } = this._getPortPairs(origin, destination);

    // return false if the given ports cannot be found
    if (!a || !b) {
      return false;
    }

    if (isNull(a.parentIsland)) {
      return false;
    }
    return a.parentIsland === b.parentIsland;
  },

  getParentIsland(abbreviation) {
    return (locationsMap[abbreviation] && locationsMap[abbreviation].parentIsland && locationsMap[locationsMap[abbreviation].parentIsland]) || false;
  },

  /**
   * Returns all locations from repository
   */
  getAllPorts() {
    return locations;
  },

  /**
   * Returns all locations from repository
   */
  getPopularPorts() {
    return popularLocations;
  },

  /**
   * Get a location using it's abbreviation
   * @param {String} abbreviation
   */
  getPort(abbreviation) {
    return locationsMap[abbreviation];
  },

  /**
   * Returns the name of a location using it's abbreviation
   * @param {String} abbreviation
   */
  getPortName(abbreviation) {
    const port = this.getPort(abbreviation);
    return (port && port.short) || abbreviation;
  },

  /**
   * Returns the name of a location using it's abbreviation
   * @param {String} abbreviation
   */
  getPortFullName(abbreviation) {
    const port = this.getPort(abbreviation);
    return (port && port.alias) || abbreviation;
  },

   /**
   * Returns the name of a port in english using its abbreviation
   * @param {String} abbreviation
   */
  getStationName(abbreviation) {
    const port = this.getPort(abbreviation);
    return (port && port.stationName) || abbreviation;
  },

  /**
   * Returns the common ferry providers for the connection between to given ports
   * If cache mode is enabled, it will always return FerryhopperCRS as a provider
   * If no provider is common between these ports, it will return FerryhopperCRS
   *
   * @param {String} origin
   * @param {String} destination
   */
  getCombinationProviders(origin, destination) {
    let providersA = locationsMap[origin].providers;
    let providersB = locationsMap[destination].providers;
    return _intersectionWith(providersA, providersB);
  }
};
